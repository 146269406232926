














import {Vue, Component, Prop} from 'vue-property-decorator';
import PortalInput from "@/components/common/PortalInput.vue";
import Application from "@/state/Application";
import SickLeaveService from "@/services/payroll/SickLeaveService";
import {ifValid, processError} from "@/utils/ComponentUtils";
import {IEmployment} from "@/dto/payroll/IEmployment";
import {SickLeaveBalance, UpdateSickLeaveBalancePayload} from "@/components/payroll/sickLeave/SickLeave";

@Component({
	components: {PortalInput}
})
export default class SickLeaveBalanceEditModal extends Vue {

	@Prop()
	private employment!: IEmployment;

	@Prop()
	private callback!: () => void

	private balance: SickLeaveBalance | null = null

	get totalBalanceHours(): number {
		return +this.balance!.balance + +this.balance!.carryover
	}

	private loadAvailableBalance() {
		Application.startLoading()
		SickLeaveService.getSickLeaveBalance(this.employment.id!).then(
			res => {
				this.balance = res.data;
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	public created() {
		this.loadAvailableBalance()
	}

	private edit() {
		ifValid(this, () => {
			Application.startLoading();
			SickLeaveService.updateSickLeaveBalance(
				new UpdateSickLeaveBalancePayload(
					{
						employmentId: this.employment!.id,
						carryover: +this.balance!.carryover.toString().replaceAll(",", ".")
					}
				)
			).then(
				() => {
					this.$modal.hideAll();
					Application.stopLoading();
					this.callback()
				},
				err => processError(err, this)
			)
		})
	}

}
