







































































import {Component, Prop, Vue} from "vue-property-decorator";
import PortalInput from "@/components/common/PortalInput.vue";
import moment from "moment/moment";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import Application from "@/state/Application";
import PayrollService from "@/services/payroll/PayrollService";
import {Paystub, PaystubFilter} from "@/dto/payroll/PayrollDTO";
import {processError} from "@/utils/ComponentUtils";
import CreatePaystubModal from "@/components/payroll/business/employments/employee/CreatePaystubModal.vue";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import PaystubReportView from "@/components/payroll/business/employments/employee/PaystubReportView.vue";

@Component({
	computed: {
		PaystubFilter() {
			return PaystubFilter
		}
	},
	components: {PaystubReportView, PaginationComponent, PortalDate, BTPortalCollapse, PortalInput}
})
export default class EmployeePaystub extends Vue {
	@Prop()
	private employee!: EmployeeDto

	private year: string = moment().format("YYYY")

	private filter = new PaystubFilter(
		{
			year: moment().format("YYYY"),
			employmentsId: this.employee.id,
		}
	)

	private totalPages = 0

	private paystubs: Array<Paystub> = []

	private loadPaystub() {
		Application.startLoading()
		PayrollService.getPaystubs(this.filter).then(
			res => {
				this.paystubs = res.data.data
				this.totalPages = res.data.countOfPages
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private changePage(page: number) {
		this.filter.page = page - 1;
		this.loadPaystub();
	}

	public created() {
		this.loadPaystub()
	}

	private reset() {
		this.filter = new PaystubFilter(
			{
				year: moment().format("YYYY"),
				employmentsId: this.employee.id,
			}
		)
		this.loadPaystub()
	}

	private createPaystub() {
		this.$modal.show(
			CreatePaystubModal,
			{
				employmentId: this.employee.id,
				onSuccess: this.loadPaystub,
			}
		)
	}
}

