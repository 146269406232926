














import {Component, Prop, Vue} from "vue-property-decorator";
import PTOView from "@/components/payroll/policies/pto/PTOView.vue";
import SLPView from "@/components/payroll/policies/sickLeave/SLPView.vue";
import {IEmployment} from "@/dto/payroll/IEmployment";

@Component({
	components: {SLPView, PTOView}
})
export default class PoliciesView extends Vue {

	@Prop()
	private employee!: IEmployment

}

