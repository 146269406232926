




















import {Component, Vue} from "vue-property-decorator";
import Details from "@/components/payroll/business/employments/employee/Details.vue";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import EmployeeService from "@/services/EmployeeService";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import { processError } from "@/utils/ComponentUtils";
import Workspaces from "@/state/Workspaces";
import VacationView from "@/components/payroll/vacation/EmploymentVacationList.vue";
import Tariffication from "@/components/payroll/business/employments/employee/Tariffication.vue";
import EmployeePayrollDetails from "@/components/payroll/business/employments/employee/EmployeePayrollDetails.vue";
import EmployeePaystub from "@/components/payroll/business/employments/employee/EmployeePaystub.vue";
import PoliciesView from "@/components/payroll/policies/PoliciesView.vue";
import EmploymentSickLeaveList from "@/components/payroll/sickLeave/EmploymentSickLeaveList.vue";

const AppModule = namespace("App");

@Component({
	components: {Details}
})
export default class EmployeeCard extends Vue {

	private components: Map<string, typeof Vue> = new Map<string, typeof Vue>(
		[
			["Details", Details]
		]
	)

	private employee: EmployeeDto = new EmployeeDto()

	private message: string | null = null;

	private reload: () => void = this.loadEmployee

	private successful = false;

	@AppModule.Action
	private stopLoading!: () => void

	@AppModule.Action
	private startLoading!: () => void

	private loadEmployee(): Promise<void> {
		this.startLoading();
		return EmployeeService.getEmployeeById(this.$route.params.employeeId).then(
			res => {
				this.employee = res.data;
				this.stopLoading();
			},
			err => processError(
				err,
				this,
				{
					callback: () => this.$modal.hideAll()
				}
			)
		)
	}

	public created() {
		this.loadEmployee()
		if (Workspaces.isUserSublimeEmployee) {
			this.components.set("Tariffication", Tariffication)
			this.components.set("Payroll details", EmployeePayrollDetails)
			this.components.set("Paystub", EmployeePaystub)
		}
		this.components.set("Policies", PoliciesView)
		this.components.set("Vacations", VacationView)
		this.components.set("Sick Leaves", EmploymentSickLeaveList)
	}

}

