












































































import {Component, Prop, Vue} from "vue-property-decorator";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import {PayrollDetailsDTO, PayrollDetailsFilter} from "@/dto/payroll/PayrollDTO";
import PortalDate from "@/components/common/PortalDate.vue";
import CreatePayrollDetailsModal from "@/components/payroll/business/employments/employee/CreatePayrollDetailsModal.vue";
import PayrollService from "@/services/payroll/PayrollService";
import moment from "moment";
import PortalInput from "@/components/common/PortalInput.vue";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import {ifValid, processError} from "@/utils/ComponentUtils";
import {namespace} from "vuex-class";
import SelectOption from "@/components/common/SelectOption";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {prettyEnum} from "@/utils/StringUtils";
import Application from "@/state/Application";
import PayrollDetailsReportView from "@/components/payroll/business/employments/employee/PayrollDetailsReportView.vue";

const AppModule = namespace("App");

@Component({
	methods: {prettyEnum},
	computed: {
		moment() {
			return moment
		}
	},
	components: {PayrollDetailsReportView, PortalSelect, PaginationComponent, PortalInput, PortalDate, BTPortalCollapse}
})
export default class EmployeePayrollDetails extends Vue {
	@Prop()
	private employee!: EmployeeDto

	private payrollDetailsTypeOptions: Array<SelectOption> = [
		SelectOption.builder().title("General").value("GENERAL").build(),
		SelectOption.builder().title("Regular").value("REGULAR").build(),
		SelectOption.builder().title("All").value(null).build()
	];

	private payrollDetails: Array<PayrollDetailsDTO> = []

	private filter: PayrollDetailsFilter = new PayrollDetailsFilter()

	@AppModule.Action
	private stopLoading!: () => void

	@AppModule.Action
	private startLoading!: () => void

	private totalPages = 0

	public created(): void {
		this.filter.employmentId = this.employee.id
		this.loadPayrollDetails();
	}

	private loadPayrollDetails(): void {
		ifValid(this, () => {
			Application.startLoading()
			PayrollService.getPayrollDetails(this.filter).then(
				res => {
					if (res.data) {
						this.payrollDetails = res.data.data;
						this.totalPages = res.data.countOfPages
					}
					Application.stopLoading()
				},
				err => processError(err, this)
			)
		})
	}

	private createPayrollDetails(): void {
		this.$modal.show(
			CreatePayrollDetailsModal,
			{
				employee: this.employee,
				onSuccess: this.loadPayrollDetails,
			}
		)
	}

	private changePage(page: number) {
		this.filter.page = page - 1;
		this.loadPayrollDetails();
	}

	private reset(): void {
		this.filter = new PayrollDetailsFilter();
		this.filter.employmentId = this.employee.id
		this.loadPayrollDetails()
	}
}

