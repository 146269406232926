
































































































import {Component, Prop, Vue} from "vue-property-decorator";
import CreateDemoPayrollDetailsPayload, {CreateGeneralPayrollDetails, CreatePayrollFormPayload} from "@/dto/payroll/PayrollDTO";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import EmployeeDto from "@/dto/payroll/EmployeeDto";
import {PayType} from "@/constants/TarifficationConstants";
import {ifValid, processError} from "@/utils/ComponentUtils";
import PayrollService from "@/services/payroll/PayrollService";
import {namespace} from "vuex-class";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import Application from "@/state/Application";
import moment, {Moment} from "moment";

type payloadType = "SINGLE" | "GENERAL"

const AppModule = namespace("App");

@Component({
	computed: {
		PayType() {
			return PayType
		}
	},
	components: {PortalCheckbox, PortalDate, PortalInput}
})
export default class CreatePayrollDetailsModal extends Vue {
	@Prop()
	private employee!: EmployeeDto

	@Prop()
	private onSuccess!: () => void

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	private successful = true;

	private infoReq = new CreateDemoPayrollDetailsPayload()

	private message = "";

	private mode: payloadType = "SINGLE"

	private req = new CreatePayrollFormPayload();

	private start: Moment | null = null
	private end: Moment | null = null

	public created(): void {
		this.req.employmentId = this.employee.id
		this.infoReq.employmentId = this.employee.id
	}

	private loadPayPeriodInfo() {
		if (this.req.payPeriodStart && this.req.payPeriodEnd) {
			this.infoReq.payPeriodEnd = this.req.payPeriodEnd;
			this.infoReq.payPeriodStart = this.req.payPeriodStart;
			Application.startLoading()
			PayrollService.getPayrollDetailsPeriodDetails(this.infoReq).then(
				res => {
					this.req.regularHours = res.data.regularHours != 0 ? res.data.regularHours : null
					this.req.overtimeHours = res.data.overtimeHours != 0 ? res.data.overtimeHours : null
					Application.stopLoading()
				},
				err => processError(err, this)
			)
		}
	}

	private create(): void {
		ifValid(this, () => {
			this.startLoading();
			PayrollService.createPayrollDetails([this.req]).then(
				() => {
					this.successful = true;
					this.message = ""
					this.$modal.hideAll()
					this.stopLoading()
					this.onSuccess()
				},
				err => processError(
					err,
					this,
					{
						callback: () => this.$modal.hideAll()
					}
				)
			)
		})
	}
}

