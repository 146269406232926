














import {Vue, Component, Prop} from 'vue-property-decorator';
import PortalInput from "@/components/common/PortalInput.vue";
import Application from "@/state/Application";
import PayrollService from "@/services/payroll/PayrollService";
import {ifValid, processError} from "@/utils/ComponentUtils";
import {IEmployment} from "@/dto/payroll/IEmployment";
import {UpdateVacationCarryoverPayload, VacationBalance} from "@/dto/payroll/VacationDTO";
import VacationService from "@/services/payroll/VacationService";

@Component({
	components: {PortalInput}
})
export default class VacationBalanceEditModal extends Vue {

	@Prop()
	private employment!: IEmployment;

	@Prop()
	private callback!: () => void

	private balance: VacationBalance | null = null

	get totalBalanceHours(): number {
		return +this.balance!.balance + +this.balance!.carryover
	}

	private loadAvailableBalance() {
		Application.startLoading()
		VacationService.getVacationBalance(this.employment.id!).then(
			res => {
				this.balance = res.data;
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	public created() {
		this.loadAvailableBalance()
	}

	private edit() {
		ifValid(this, () => {
			Application.startLoading();
			VacationService.updateVacationBalance(
				new UpdateVacationCarryoverPayload(
					{
						employmentId: this.employment!.id,
						carryover: +this.balance!.carryover.toString().replaceAll(",", ".")
					}
				)
			).then(
				() => {
					this.$modal.hideAll();
					Application.stopLoading();
					this.callback()
				},
				err => processError(err, this)
			)
		})
	}

}
