






























































import {Vue, Component, Prop} from 'vue-property-decorator';
import {IEmployment} from "@/dto/payroll/IEmployment";
import {VacationDto, VacationFilter} from "@/dto/payroll/VacationDTO";
import Application from "@/state/Application";
import PayrollService from "@/services/payroll/PayrollService";
import {processError} from "@/utils/ComponentUtils";
import moment, {Moment} from "moment-timezone";
import {prettyEnum} from "@/utils/StringUtils";
import VacationCreationModal from "@/components/payroll/vacation/VacationCreationModal.vue";
import VacationOptionsModal from "@/components/payroll/vacation/VacationOptionsModal.vue";
import VacationBalanceEditModal from "@/components/payroll/vacation/VacationBalanceEditModal.vue";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import VacationService from "@/services/payroll/VacationService";
import PortalDate from "@/components/common/PortalDate.vue";

@Component({
	components: {PortalDate, PaginationComponent},
	methods: {
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		},
		prettyEnum
	}
})
export default class EmploymentVacationList extends Vue {

	private vacations: Array<VacationDto> = [];
	private totalPages = 0

	@Prop()
	private employee!: IEmployment

	private filter = new VacationFilter(
		{
			employmentsId: [this.employee.id!],
		}
	)

	private loadVacations() {
		Application.startLoading()
		VacationService.getPageOfVacations(this.filter).then(
			res => {
				this.vacations = res.data.data
				this.totalPages = res.data.countOfPages
				Application.stopLoading()
			},
			err => processError(err, this)
		)
	}

	public created() {
		this.loadVacations()
	}

	private showCreationWindow() {
		this.$modal.show(
			VacationCreationModal,
			{
				employment: this.employee,
				callback: () => this.loadVacations()
			}
		)
	}

	private showOptions(vacation: VacationDto) {
		this.$modal.show(
			VacationOptionsModal,
			{
				vacation: vacation,
				callback: () => this.loadVacations()
			}
		)
	}

	private showCarryoverModal() {
		this.$modal.show(
			VacationBalanceEditModal,
			{
				employment: this.employee,
				callback: () => this.loadVacations()
			}
		)
	}

	private changePage(page: number){
		this.filter.page = page - 1;
		this.loadVacations();
	}

	private reset() {
		this.filter = new VacationFilter(
			{
				employerId: this.$wss.getCurrent.id,
				startDate: moment().format("YYYY-MM-DD")
			}
		)
		this.loadVacations()
	}

}
